<template>
    <div id="start-wrapper">
        <div class="container">
            <div class="row">
                <div class="title">
                    <h3 class="yellow" data-aos="zoom-in">كيف بدأ اوس يدرب اونلاين؟</h3>
                </div>
                <div class="text" data-aos="zoom-out">
                    <p>التدريب الاونلاين ليس جديدا عليه لأنه كان يدرب أونلاين منذ أكثر من 4 سنوات بشكل شبه مجاني ليكسب خبره
                        اكثر ولكي يتعلم على جميع الأجسام وجميع الأعمار</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'StartComponent'
}
</script>

<style lang="scss">
#start-wrapper {
    width: 100%;
    margin: 50px 0 0 0;

    .container {
        width: 100%;

        .row {
            width: 90%;
            max-width: 1600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;

            .title {
                h3 {
                    font-size: clamp(24px, 2.083vw, 40px);
                    margin: 0 0 40px 0;
                }
            }

            .text {
                p {
                    font-size: clamp(16px, 1.250vw, 24px);
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    #start-wrapper {
        .container {
            .row {
                .title {
                    h3 {
                        margin: 0 0 20px 0;
                        text-align: center;
                    }
                }

                .text {
                    p {
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
