<template>
    <div id="goal-wrapper">
        <div class="container">
            <div class="row">
                <div class="title">
                    <h3 class="yellow" data-aos="zoom-in">ما هو الهدف من التدريب الاونلاين بالنسبة لاوس؟</h3>
                </div>
                <div class="list">
                    <ul>
                        <li data-aos="fade-left" data-aos-delay="0">
                            <div class="text">
                                <img src="@/assets/images/checkmark.png" alt="">
                                <p>
                                    جعل المتدرب يصل لأفضل نتيجه له على
                                </p>
                            </div>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="200">
                            <div class="text">
                                <img src="@/assets/images/checkmark.png" alt="">
                                <p>
                                    تعليم المتدرب بحيث يجعله يفهم أساليب تدريب جديدة وأساليب تغذية جديده
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'GoalComponent'
}
</script>

<style lang="scss">
#goal-wrapper {
    width: 100%;
    margin: 50px 0 0 0;

    .container {
        width: 100%;

        .row {
            width: 90%;
            max-width: 1600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;

            .title {
                h3 {
                    font-size: clamp(24px, 2.083vw, 40px);
                    margin: 0 0 40px 0;
                    text-align: center;
                }
            }

            .list {
                ul {
                    li {
                        list-style: none;

                        .text {
                            display: flex;
                            gap: 20px;

                            img {
                                width: 30px;
                                height: 30px;
                            }

                            p {
                                font-size: clamp(16px, 1.250vw, 24px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    #goal-wrapper {
        .container {
            .row {
                .title {
                    h3 {
                        margin: 0 0 20px 0;
                    }
                }
            }
        }
    }
}
</style>
