<template>
  <div id="header-wrapper">
    <div class="container">
      <div class="grid">
        <div class="col-1" data-aos="fade-up">
          <div class="sun-effect"></div>
          <img src="@/assets/images/aws.png" width="908" height="1635" alt="">
          <div class="shadow"></div>
        </div>
        <div class="col-2">
          <div class="top">
            <div class="logo" data-aos="fade-down">
              <img src="@/assets/logo.png" width="503" height="394" alt="">
            </div>
            <p id="text-1">
              <span class="char" style="animation-delay: 0ms;">
                احصل&nbsp;
              </span>
              <span class="char" style="animation-delay: 36ms;">
                على&nbsp;
              </span>
              <span class="char" style="animation-delay: 42ms;">
                <strong class="yellow">
                  جسم&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 54ms;">
                <strong class="yellow">
                  أحلامك&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 60ms;">
                مع&nbsp;
              </span>
              <span class="char" style="animation-delay: 84ms;">
                <strong class="yellow">
                  برامج&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 90ms;">
                <strong class="yellow">
                  التغذيه&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 102ms;">
                <strong class="yellow">
                  والتدريب.
                </strong>
              </span>
            </p>
            <p id="text-2">
              <span class="char" style="animation-delay: 108ms;">
                نحن&nbsp;
              </span>
              <span class="char" style="animation-delay: 150ms;">
                دائما&nbsp;
              </span>
              <span class="char" style="animation-delay: 156ms;">
                معك&nbsp;
              </span>
              <span class="char" style="animation-delay: 198ms;">
                <strong class="yellow">
                  خطوة&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 204ms;">
                <strong class="yellow">
                  بخطوه&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 222ms;">
                في&nbsp;
              </span>
              <span class="char" style="animation-delay: 228ms;">
                رحلة&nbsp;
              </span>
              <span class="char" style="animation-delay: 234ms;">
                الوصول&nbsp;
              </span>
              <span class="char" style="animation-delay: 240ms;">
                <strong class="yellow">
                  لجسم&nbsp;
                </strong>
              </span>
              <span class="char" style="animation-delay: 300ms;">
                <strong class="yellow">
                  أحلامك.
                </strong>
              </span>
            </p>
          </div>
          <div class="bottom">
            <div class="buttons">
              <button @click="scrollToBottom" class="get-started" data-aos="fade-left">
                <span>
                  تواصل معنا
                </span>
              </button>
              <a href="https://www.youtube.com/@awswattad2732" class="watch-video" data-aos="fade-right">
                <span class="play-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                    viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                  </svg>
                </span>
                <span class="link-text">
                  قناة اليوتيوب
                </span>
              </a>
            </div>
            <div class="trainers-data">
              <div class="data" data-aos="fade-left">
                <span class="number percent" data-value="97">
                  000
                </span>
                <span class="subtext"><small>نسبة نجاح</small></span>
              </div>
              <div class="data" data-aos="fade-left" data-aos-delay="300">
                <span class="number plus" data-value="970">
                  000
                </span>
                <span class="subtext"><small>عضو انضم</small></span>
              </div>
              <div class="data" data-aos="fade-left" data-aos-delay="600">
                <span class="number plus" data-value="135">
                  000
                </span>
                <span class="subtext"><small>برنامج غذائي وتدريب</small></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    msg: String
  },
  mounted: function () {
    this.numberCounter()
  },
  methods: {
    scrollToBottom() {
      document.getElementById('contact-form-wrapper').scrollIntoView({ behavior: 'smooth' })
    },
    numberCounter() {
      const valueDisplays = document.querySelectorAll('.number')
      // const valueDisplaysPercent = document.querySelectorAll('.number.percent')
      const interval = 5000
      valueDisplays.forEach(valueDisplay => {
        // console.log(valueDisplay)
        let startValue = 0
        const endValue = parseInt(valueDisplay.getAttribute('data-value'))
        const duration = Math.floor(interval / endValue)
        const counter = setInterval(function () {
          startValue += 1
          valueDisplay.textContent = startValue
          if (startValue === endValue) {
            clearInterval(counter)
            const containsPlus = valueDisplay.classList.contains('plus')
            if (containsPlus) {
              valueDisplay.textContent = endValue + '+'
            } else {
              valueDisplay.textContent = endValue + '%'
            }
          }
        }, duration)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#header-wrapper {
  // width: 100%;
  // // height: 100dvh;
  // max-height: 100dvh;
  // background-color: #20242e;
  // box-shadow: 1px 1px 10px 10px #485169;

  .container {
    // width: 100%;
    // height: 100%;
    // padding: 50px 0 0 0;

    // background-color: #20242e;
    // background-image: url('@/assets/images/blob-scene-haikei.svg');
    // background-position: center;
    // background-attachment: fixed;
    // background-repeat: no-repeat;
    // background-size: cover;

    // &::before {
    //   content: '';
    //   opacity: 0.8;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   background: #242b3b;
    //   height: 100%;
    // }

    .grid {
      box-shadow: 1px 1px 10px 10px #485169;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 50px 0 0 0;
      backdrop-filter: blur(10px);

      .col-1 {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .sun-effect {
          position: absolute;
          // top: 30%;
          /* Adjust the vertical position of the sun */
          // left: 50%;
          /* Adjust the horizontal position of the sun */
          // transform: translate(-50%, -50%);
          width: 100%;
          /* Set the size of the sun */
          height: 100%;
          background-image: url('@/assets/images/sun.png');
          overflow: hidden;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          /* Replace 'sun-image.png' with your sun image URL */
          background-size: cover;
          z-index: -1;
          /* Set the z-index to be behind the portfolio image */
        }

        img {
          width: 50%;
          height: fit-content;
          // margin: auto;
        }

        .shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 30px 30px var(--darkBlue);
          // top: 0;
          // left: 0;
        }
      }

      .col-2 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .top {
          width: 100%;

          .logo {
            margin: 0 0 50px 0;

            img {
              width: 200px;
              height: auto;
              margin: auto;
            }
          }

          p {
            display: block;
            // text-align: center;
            font-size: clamp(20px, 2.604vw, 50px);

            .char {
              height: 40px;
              animation: an 1s ease-out 1 both;
              display: inline-block;
            }
          }
        }

        .bottom {
          width: 100%;
          margin: 40px 0 0 0;

          .buttons {
            display: flex;
            gap: 50px;

            span {
              font-size: 18px;
              font-weight: 900;
            }

            .get-started {
              background-color: var(--yellow);
              color: var(--darkBlue);
              border-radius: 12px;
              padding: 10px 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
            }

            .watch-video {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;

              .play-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background-color: #20242e;
                box-shadow: 0px 0px 4px 3px #48516970;
                border-radius: 50%;

                svg {
                  width: 50%;
                  fill: var(--yellow);
                  vertical-align: middle;
                  transform: translateX(3px);
                }
              }

              .link-text {
                color: var(--yellow);
                vertical-align: middle;
              }
            }
          }

          .trainers-data {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin: 50px 0;
            gap: 20px;
            padding: 0 0 0 20px;

            .data {
              position: relative;
              background-color: #20242e;
              box-shadow: 0px 0px 4px 3px #48516970;
              border-radius: 14px;
              padding: 6px;
              text-align: center;

              .number {
                font-size: 30px;
                font-weight: 900;
                display: block;
              }

              .subtext {
                font-size: 18px;
              }

              // &:nth-child(2) {

              //   &::after,
              //   &::before {
              //     content: '';
              //     width: 1px;
              //     height: 100%;
              //     background-color: aliceblue;
              //     display: block;
              //     position: absolute;
              //     top: 0;
              //   }

              //   &::after {
              //     left: 40%;
              //   }

              //   &::before {
              //     right: -50%;
              //   }
              // }
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 900px) {
  #header-wrapper {
    .container {
      .grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: 'col-2'
          'col-1';
        text-align: center;
        // gap: 40px;
        padding: 50px 20px 0;

        .col-1 {
          grid-area: col-1;
        }

        .col-2 {
          grid-area: col-2;

          .top {
            .logo {
              margin: 0 0 20px 0;
            }
          }

          .bottom {
            margin: 20px 0 0 0;

            .buttons {
              justify-content: center;
            }

            .trainers-data {
              padding: 0;
            }
          }
        }

      }
    }
  }
}

@media screen and (max-width: 480px) {
  #header-wrapper {
    .container {
      .grid {
        .col-1 {
          img {
            width: 70%;
          }
        }

        .col-2 {

          .top {
            .logo {
              img {
                width: 120px;
              }
            }
          }

          .bottom {
            .buttons {
              flex-direction: column;
              gap: 20px;
            }

            .trainers-data {
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 1fr 1fr;
              // row-gap: 40px;

              .data {
                &:nth-child(3) {
                  grid-column: 1 / span 2;
                }
              }
            }
          }
        }

      }
    }
  }
}

@keyframes an {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
  }

  to {
    opacity: 1;
    transform: perspective(500px) translate3d(0, 0, 0);
  }
}
</style>
