<template>
    <div id="copyright-wrapper">
        <div class="container">
            <div class="row">
                <div class="grid">
                    <div class="logo">
                        <img src="@/assets/logo.png" width="503" height="394" alt="">
                    </div>
                    <div class="copyright-text">
                        <p>
                            &copy; 2023
                            <a href="https://success.growmax.co.il">
                                GrowMax
                            </a>
                            . جميع الحقوق محفوظة
                        </p>
                    </div>
                    <div class="social-links">
                        <ul class="list">
                            <!-- <li>
                                <a href="https://facebook.com">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path
                                            d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                                    </svg>
                                </a>
                            </li> -->
                            <li>
                                <a href="https://instagram.com/aws_wattad21g">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                        viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                        <path
                                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'CopyRights'
}
</script>

<style lang="scss">
#copyright-wrapper {
    width: 100%;

    .container {
        width: 100%;

        .row {
            width: 90%;
            max-width: 1600px;
            padding: 20px;
            margin: auto;
            border-top: 2px solid rgb(67, 79, 107);

            .grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                justify-items: center;
                align-items: center;

                .logo {
                    justify-self: self-start;

                    img {
                        width: 100px;
                        height: auto;
                    }
                }

                .copyright-text {
                    p {
                        font-size: 16px;
                        text-align: center;

                        a {
                            color: #fff;

                            &:visited {
                                color: #fff;
                            }
                        }
                    }
                }

                .social-links {
                    justify-self: self-end;

                    .list {
                        li {
                            display: inline-block;
                            margin: 0 10px;

                            a {
                                svg {
                                    fill: var(--yellow);
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    #copyright-wrapper {
        .container {
            .row {
                .grid {
                    grid-template-columns: 1fr;
                    gap: 20px;
                    grid-template-areas:
                        'logo'
                        'social-links'
                        'copyright-text';

                    .logo {
                        grid-area: logo;
                    }

                    .copyright-text {
                        grid-area: copyright-text;
                    }

                    .social-links {
                        grid-area: social-links;
                    }

                    .logo,
                    .social-links {
                        justify-self: center;
                    }
                }
            }
        }
    }
}
</style>
