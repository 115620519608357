<template>
    <div>
        404
    </div>
</template>
<script lang="js">
export default {
    name: 'PageNotFound'
}
</script>
