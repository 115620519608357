<template>
  <div id="lp-wrapper">
    <div class="container">
      <div class="row">
        <div class="parallax-top">
          <HeaderComponent />
        </div>
        <div class="parallax-bottom">
          <AboutComponent />
          <StartComponent />
          <GoalComponent />
          <CustomerGets />
          <ResultsComponent />
          <PricePlans />
          <ContactForm />
          <CopyRights />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from '@/components/HeaderComponent.vue'
import AboutComponent from '@/components/AboutComponent.vue'
import StartComponent from '@/components/StartComponent.vue'
import GoalComponent from '@/components/GoalComponent.vue'
import CustomerGets from '@/components/CustomerGets.vue'
import ResultsComponent from '@/components/ResultsComponent.vue'
import PricePlans from '@/components/PricePlans.vue'
import CopyRights from '@/components/CopyRights.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
  name: 'LayoutView',
  components: {
    HeaderComponent,
    AboutComponent,
    StartComponent,
    GoalComponent,
    CustomerGets,
    ResultsComponent,
    PricePlans,
    ContactForm,
    CopyRights
  }
}
</script>

<style lang="scss">
// #lp-wrapper {
//   .container {
.row {
  position: relative;

  // height: 2000px;
  /* Set the container as a reference for positioning */

  // .top-section {
  //   position: fixed;
  //   /* Fix the top section to the top of the viewport */
  //   top: 0;
  //   /* Position it at the top of the viewport */
  //   width: 100%;
  //   max-height: 100dvh;
  //   /* Make it span the entire width of the viewport */
  //   background-color: #f0f0f0;
  //   /* Example background color; customize as needed */
  //   z-index: 1;
  //   /* Set a higher z-index to make sure it's above the bottom section */
  // }

  // .bottom-section {
  //   /* Add padding-top to create space for the fixed top section */
  //   padding-top: 100px;
  //   /* Adjust this value according to your top section's height */
  //   /* Add other styles as needed for the bottom section */
  // }
}

//   }
// }
</style>
