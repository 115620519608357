<template>
    <div id="plan-card" v-bind:class="card.popular ? 'popular' : ''">
        <div class="card-head">
            <small>{{ card.id.toUpperCase() }}</small>
        </div>
        <div class="card-body">
            <div class="top">
                <p>
                    <strong>
                        {{ card.price }}
                    </strong>
                    /
                    <small>
                        {{ card.duration }}
                    </small>
                </p>
            </div>
            <div class="bottom">
                <p>
                    <strong>
                        {{ card.desc }}
                    </strong>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="js">

export default {
    name: 'PlanCard',
    props: {
        card: Object
    }
}
</script>

<style lang="scss">
#plan-card {
    text-align: center;
    background: #242b3b;
    box-shadow: 5px 5px 10px #0e1118,
        -5px -5px 10px #3a455e;
    // box-shadow: -5px -5px 10px rgba(34, 38, 46, 0.5),
    //     5px 5px 10px rgba(60, 72, 99, 0.5);
    // box-shadow: -5px -5px 1px 0px rgba(34, 38, 46, 0.5),
    //     4px 4px 0px 0px rgba(60, 72, 99, 0.5);
    // box-shadow: -5px -5px 1px rgba(34, 38, 46, 0.5), 4px 4px 0px 0px rgba(60, 72, 99, 0.5);
    border-radius: 20px;
    padding: 10px 30px;

    &.popular {
        background-color: var(--yellow);
        color: rgb(36, 43, 59);
    }

    .card-head {
        small {
            font-size: 1.3rem;
        }
    }

    .card-body {
        .top {
            p {
                font-size: 2rem;

                strong {
                    font-size: 2rem;
                }

                small {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
</style>
