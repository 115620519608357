<template>
    <div id="about-wrapper">
        <div class="container">
            <div class="row">
                <div class="about-aws">
                    <h3 class="yellow" data-aos="zoom-in">من هو اوس وتد؟</h3>
                    <ul>
                        <li data-aos="fade-left" data-aos-delay="0">
                            <AWSIcon class="icon" />
                            <p>
                                مدرب شخصي واخصائي تغذية رياضية و تغذية لاعب كمال الاجسام بالتحديد
                            </p>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="100">
                            <AWSIcon class="icon" />
                            <p>
                                منافس في بطولات ويسعى للكرت الاحتراف
                            </p>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="200">
                            <AWSIcon class="icon" />
                            <p>
                                يوتيوبر في مجال التدريب والتغذية وكمال الأجسام
                            </p>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="300">
                            <AWSIcon class="icon" />
                            <p>
                                بدأ مسيرته قبل 8 سنوات واكتسب خبره رهيبه في مجال التدريب والتغذية الرياضية بالاخص
                            </p>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="400">
                            <AWSIcon class="icon" />
                            <p>
                                خلال 4 سنوات تعامل مع أكثر من 800 متدرب
                            </p>
                        </li>
                        <li data-aos="fade-left" data-aos-delay="500">
                            <AWSIcon class="icon" />
                            <p>
                                لديه علاقات قوية مع مدربين محترفين
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="aws-image">
                    <img src="@/assets/images/about-aws.webp" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AWSIcon from './icons/AwsIcon.vue'

export default {
    name: 'AboutComponent',
    components: {
        AWSIcon
    }
}
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#about-wrapper {
    width: 100%;
    margin: 50px 0 0 0;

    .container {
        width: 100%;

        .row {
            width: 90%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            margin: auto;
            gap: 40px;

            .about-aws {
                // margin: auto;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                // align-items: center;

                h3 {
                    font-size: clamp(24px, 2.083vw, 40px);
                    margin: 0 0 40px 0;
                    text-align: center;
                }

                ul {

                    li {
                        list-style: none;
                        display: grid;
                        grid-template-columns: auto 1fr;
                        align-items: center;
                        gap: 20px;
                        margin: 0 0 20px 0;

                        span {
                            display: block;
                            width: 50px;
                            height: 50px;
                        }

                        p {
                            // margin: 0 20px 0 0;
                            font-size: clamp(16px, 1.250vw, 24px);
                        }
                    }
                }
            }

            .aws-image {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    border-radius: 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    #about-wrapper {

        .container {
            .row {
                flex-direction: column;

                .about-aws {
                    h3 {
                        margin: 0 0 20px 0;
                    }
                }
            }
        }
    }
}
</style>
