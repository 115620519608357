<template>
    <div id="plans-wrapper">
        <div class="container">
            <div class="row">
                <div class="title">
                    <h3 class="yellow" data-aos="zoom-in">اختر الخطة الأفضل</h3>
                </div>
                <div class="cards">
                    <PlanCard v-for="(card, index) in cards" :key="card.id" :card="card" data-aos="flip-right"
                        :data-aos-delay="'200' * index" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import PlanCard from './PlanCard.vue'
export default {
    name: 'PricePlans',
    components: {
        PlanCard
    },
    data() {
        return {
            cards: [
                {
                    id: 'lite',
                    title: '',
                    duration: '4 أسابيع',
                    price: '350₪',
                    popular: false,
                    desc: ''
                },
                {
                    id: 'basic',
                    title: '',
                    duration: '12 أسبوع',
                    price: '900₪',
                    popular: true,
                    desc: 'الرزمة المفضلة لدى المتدربين'
                },
                {
                    id: 'pro',
                    title: '',
                    duration: '24 أسبوع',
                    price: '1600₪',
                    popular: false,
                    desc: ''
                },
                {
                    id: 'vip',
                    title: '',
                    duration: 'شهر',
                    price: '500₪',
                    popular: false,
                    desc: 'يمكنك التواصل مع اوس باي وقت على مدار ال 24 ساعه'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
#plans-wrapper {
    width: 100%;
    margin: 50px 0 0 0;

    .container {
        width: 100%;
        padding: 40px 0;

        .row {
            width: 90%;
            max-width: 1600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;

            .title {
                h3 {
                    font-size: clamp(24px, 2.083vw, 40px);
                    margin: 0 0 40px 0;
                    text-align: center;
                }
            }

            .cards {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    #plans-wrapper {
        .container {
            .row {
                .cards {
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 1fr;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    #plans-wrapper {
        .container {
            .row {
                .cards {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr 1fr 1fr 1fr;
                }
            }
        }
    }
}
</style>
