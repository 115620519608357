<template>
    <div id="customer-gets-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-1">

                    <div class="title">
                        <h3 class="yellow" data-aos="zoom-in">ماذا ستأخذ عند التسجيل اونلاين؟</h3>
                    </div>
                    <div class="list">
                        <ul>
                            <li data-aos="fade-left" data-aos-delay="0">
                                <div class="text">
                                    <div>
                                        <img src="@/assets/images/checkmark.png" alt="">
                                        <p>
                                            <strong class="yellow">
                                                جدول تغذيه مخصص لك:
                                            </strong>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            يوجد خيارات أكل متعددة بحيث لا تشعر بالملل من الاكل
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li data-aos="fade-left" data-aos-delay="100">
                                <div class="text">
                                    <div>
                                        <img src="@/assets/images/checkmark.png" alt="">
                                        <p>
                                            <strong class="yellow">
                                                جدول تمارين مخصص لك:
                                            </strong>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            يعني سوف أبني لك الجدول على حسب جسمك ومع كل تمرين سيكون رابط فيديو
                                            للتمرين نفسه
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li data-aos="fade-left" data-aos-delay="200">
                                <div class="text">
                                    <div>
                                        <img src="@/assets/images/checkmark.png" alt="">
                                        <p>
                                            <strong class="yellow">
                                                جدول مكملات غذائية مخصصة لك:
                                            </strong>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            للمكملات ستكون مخصصة على حسب احتياج جسمك بحيث تبعث لي
                                            فحوصات الدم الخاصة بك ومن ثم ابني لك جدول مكملات(المكملات ستكون على حسب
                                            الميزانية
                                            الخاصة
                                            بك طبعا)
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li data-aos="fade-left" data-aos-delay="300">
                                <div class="text">
                                    <div>
                                        <img src="@/assets/images/checkmark.png" alt="">
                                        <p>
                                            <strong class="yellow">
                                                جدول كارديو مخصص لك على حسب الهدف
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li data-aos="fade-left" data-aos-delay="400">
                                <div class="text">
                                    <div>
                                        <img src="@/assets/images/checkmark.png" alt="">
                                        <p>
                                            <strong class="yellow">
                                                متابعة أسبوعية:
                                            </strong>
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            سترسل لي كل أسبوع تحديث كامل يعني صورك ،ميزانك ،وعدة تفاصيل..
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-2">
                    <img src="@/assets/images/customers/1.webp" alt="">
                    <!-- <video autoplay muted loop poster="@/assets/images/manLiftingPoster.png">
                        <source src="@/assets/images/manLifting.webm" type="video/webm">
                    </video> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: 'CustomerGets'
}
</script>

<style lang="scss">
#customer-gets-wrapper {
    width: 100%;
    margin: 50px 0 0 0;

    .container {
        width: 100%;

        .row {
            width: 90%;
            max-width: 1600px;
            display: grid;
            justify-content: center;
            align-items: center;
            margin: auto;
            grid-template-columns: 1fr 1fr;
            gap: 100px;

            .col-1 {
                .title {
                    h3 {
                        font-size: clamp(24px, 2.083vw, 40px);
                        margin: 0 0 40px 0;
                    }
                }

                .list {

                    ul {
                        li {
                            list-style: none;

                            .text {

                                div {
                                    &:nth-child(1) {
                                        display: flex;
                                        align-items: center;
                                        gap: 20px;
                                    }
                                }

                                img {
                                    width: 30px;
                                    height: 30px;
                                }

                                p {
                                    font-size: clamp(16px, 1.250vw, 24px);
                                    line-height: 2;
                                }
                            }
                        }
                    }
                }
            }

            .col-2 {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: fit-content;
                    margin: auto;
                }

                video {
                    height: fit-content;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    #customer-gets-wrapper {
        .container {
            .row {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr auto;
                gap: 50px;

                .col-1 {
                    .title {
                        h3 {
                            text-align: center;
                            margin: 0 0 20px 0;
                        }
                    }

                    .list {
                        max-width: 600px;
                        margin: auto;
                    }
                }

                .col-2 {
                    img {
                        height: auto;
                    }
                }
            }
        }
    }
}
</style>
