<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 376.07 251.02">
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path class="cls-1"
					d="M288.14,150.91a99.59,99.59,0,0,0-21.83-62.32l8.28-43.72c2-11.3-2.25-22.71-11.65-31.32h0C253.52,4.94,239.92,0,225.61,0H150.48c-14.32,0-27.94,4.94-37.33,13.55h0c-9.37,8.61-13.61,20-11.65,31.32l8.31,43.72a99.62,99.62,0,0,0-21.86,62.32v.63H76.66v17.33H89.54a100.12,100.12,0,0,0,197,0h12.9V151.54H288.14ZM245.28,41.66l-4.42,24.26a99.86,99.86,0,0,0-105.66,0l-4.41-24.26c-.84-4.65.91-9.35,4.78-12.89a22.81,22.81,0,0,1,15.37-5.58h74.18a22.76,22.76,0,0,1,15.37,5.58C244.38,32.31,246.13,37,245.28,41.66Z" />
				<rect class="cls-1" x="363.47" y="151.54" width="12.6" height="17.33" rx="4.35" />
				<rect class="cls-1" y="151.54" width="12.6" height="17.33" rx="4.35" />
				<path class="cls-1"
					d="M322.34,120.26H311.26a6.37,6.37,0,0,0-6.38,6.39v67.11a6.32,6.32,0,0,0,2,4.59.5.5,0,0,0,.08.07,5.88,5.88,0,0,0,.84.67c.1.07.21.11.31.17a6.72,6.72,0,0,0,.75.4c.2.08.41.13.61.19a5.36,5.36,0,0,0,.57.17,6.57,6.57,0,0,0,1.26.13h11.08a6.57,6.57,0,0,0,1.26-.13,5.36,5.36,0,0,0,.57-.17c.2-.06.41-.11.61-.19a7.73,7.73,0,0,0,.75-.4c.1-.06.21-.1.31-.17a7.71,7.71,0,0,0,.84-.67s.05,0,.08-.07a6.36,6.36,0,0,0,2-4.59V126.65a6.37,6.37,0,0,0-6.38-6.39Z" />
				<path class="cls-1"
					d="M351.59,130.7H340.52a6.37,6.37,0,0,0-6.38,6.39v46.24a6.32,6.32,0,0,0,2,4.58.5.5,0,0,0,.08.07,5.88,5.88,0,0,0,.84.67c.1.07.21.11.31.17a6.72,6.72,0,0,0,.75.4,5.44,5.44,0,0,0,.61.19,5.36,5.36,0,0,0,.57.17,5.86,5.86,0,0,0,1.26.13h11.07a5.88,5.88,0,0,0,1.27-.13,5.36,5.36,0,0,0,.57-.17c.2-.06.41-.11.61-.19a7.73,7.73,0,0,0,.75-.4c.1-.06.21-.1.31-.17a5.88,5.88,0,0,0,.84-.67l.08-.07a6.36,6.36,0,0,0,2-4.58V137.09a6.37,6.37,0,0,0-6.39-6.39Z" />
				<path class="cls-1"
					d="M64.81,120.26H53.74a6.37,6.37,0,0,0-6.39,6.39v67.11a6.36,6.36,0,0,0,2,4.59s.06,0,.08.07a7.71,7.71,0,0,0,.84.67c.1.07.21.11.31.17a7.73,7.73,0,0,0,.75.4c.2.08.41.13.61.19a5.36,5.36,0,0,0,.57.17,6.59,6.59,0,0,0,1.27.13H64.81a6.48,6.48,0,0,0,1.26-.13,5.36,5.36,0,0,0,.57-.17,5.44,5.44,0,0,0,.61-.19,6.72,6.72,0,0,0,.75-.4c.1-.06.21-.1.31-.17a5.88,5.88,0,0,0,.84-.67.5.5,0,0,0,.08-.07,6.32,6.32,0,0,0,2-4.59V126.65a6.37,6.37,0,0,0-6.38-6.39Z" />
				<path class="cls-1"
					d="M35.55,130.7H24.48a6.39,6.39,0,0,0-5.88,3.9,6.23,6.23,0,0,0-.51,2.49v46.24a6.36,6.36,0,0,0,2,4.58l.08.07a5.88,5.88,0,0,0,.84.67c.1.07.21.11.31.17a7.73,7.73,0,0,0,.75.4c.2.08.41.13.61.19a5.36,5.36,0,0,0,.57.17,5.88,5.88,0,0,0,1.27.13H35.55a6,6,0,0,0,1.27-.13c.19,0,.37-.11.56-.17a5.44,5.44,0,0,0,.61-.19,5.94,5.94,0,0,0,.75-.4c.1-.06.21-.1.31-.17a5.88,5.88,0,0,0,.84-.67.5.5,0,0,0,.08-.07,6.32,6.32,0,0,0,2-4.58V137.09a6.37,6.37,0,0,0-6.38-6.39Z" />
				<path class="cls-2"
					d="M139.4,87a83.62,83.62,0,0,1,10.19-5.5l1.13-.49V222.54l-1.13-.64a70,70,0,0,1-10.19-5.34l-.49-.32V157.69H115.79a59.88,59.88,0,0,0,4.69,18.92,63.1,63.1,0,0,0,5.17,10l.81,1.29H112.71l-.32-.48A66,66,0,0,1,108,177.26a76.69,76.69,0,0,1-4.2-19.57v-.81c-.11-1.3-.16-2.59-.16-3.88v-1.3c0-1.61,0-3.28.16-5v-.81a76.71,76.71,0,0,1,35.09-58.71Zm-.49,14.88A65.64,65.64,0,0,0,123,121.54a63,63,0,0,0-7.19,24.34h23.12Z" />
				<path class="cls-2"
					d="M210.92,78.6l.65.32V224.49l-.65.32a97.91,97.91,0,0,1-10,2.43h-.81l-.32-1.62L188,173.7l-12,53.54h-.81c-2.16-.44-4.26-.92-6.31-1.46-1.29-.32-2.54-.65-3.72-1l-.65-.32V78.92l.65-.32c.65-.22,1.35-.43,2.1-.65a73.24,73.24,0,0,1,8.09-1.78l1-.16v95.75L182,146.85l6-27,11.81,51.92V76l1,.16A73.24,73.24,0,0,1,208.82,78C209.57,78.17,210.27,78.38,210.92,78.6Z" />
				<path class="cls-2"
					d="M272.25,145.72v.81c.11,1.72.16,3.45.16,5.17s-.05,3.46-.16,5.18v.81a76.77,76.77,0,0,1-35.09,58.55l-.49.16a62.22,62.22,0,0,1-10.19,5.5l-1.13.48V186.15h11.81v15.37a65.64,65.64,0,0,0,15.93-19.65,62.74,62.74,0,0,0,7.19-24.18H225.35V81l1.13.49A61.47,61.47,0,0,1,236.67,87l.49.16A77.29,77.29,0,0,1,263.68,116l.65,1.29H250.74l-.32-.32a65.72,65.72,0,0,0-13.26-15v43.83h35.09Z" />
			</g>
		</g>
	</svg>
</template>
<script lang="js">
export default {
	name: 'AWSIcon'
}
</script>

<style lang="scss">
.cls-1 {
	fill: #fff;
}

.cls-2 {
	fill: #e2bc00;
}

svg {
	width: 50px;
	height: auto;
}
</style>
