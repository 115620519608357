<template>
    <div id="contact-form-wrapper">
        <div class="container">
            <div class="row">
                <div class="title">
                    <h3>وظيفتي أبنيلك الجسم إلي بتتمناه ووظيفتك تلتزم!
                        <br>
                        إتركلي تفاصيلك إذا إنت جدّي بعمل تغيير بحياتك
                    </h3>
                </div>
                <div class="form-wrapper">
                    <form @submit.prevent="handleSubmit" class="form" name="contact" netlify netlify-honeypot="bot-field">
                        <div class="inputs">
                            <!-- أسم -->
                            <div class="input-group">
                                <input v-model="form.fullName" type="text" name="fullName" id="fullName" placeholder=" "
                                    autocomplete="off" :class="{ 'is-invalid': !nameIsValid }"
                                    @input="nameIsValid = validateField(form.fullName)">
                                <label for="fullName">الأسم الكامل</label>
                            </div>
                            <!-- رقم الهاتف -->
                            <div class="input-group">
                                <input v-model="form.phoneNumber" type="text" name="phoneNumber" id="phoneNumber"
                                    :pattern="'[0-9]*'" :inputmode="'numeric'" minlength="10" maxlength="10"
                                    @keypress="isNumber($event)" @input="numberIsValid = validateField(form.phoneNumber)"
                                    placeholder=" " autocomplete="off" :class="{ 'is-invalid': !numberIsValid }">
                                <label for="phoneNumber">رقم الهاتف</label>
                            </div>
                        </div>
                        <div class="submit-btn">
                            <!-- <button type="submit" id="submitBtn">
                                أرسل
                            </button> -->
                            <button type="submit" id="submitBtn" class="button" button-state="default">
                                <span class="button__content" content-type="default">
                                    <span class="button__message">أرسل</span>
                                </span>
                                <span class="button__content" content-type="loading">
                                    <span class="button__dot button__dot--first"></span>
                                    <span class="button__dot button__dot--second"></span>
                                    <span class="button__dot button__dot--thirs"></span>
                                </span>
                                <span class="button__content" content-type="success">
                                    <!-- <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <title>check</title>
                                        <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                    </svg> -->
                                    <span class="button__message">شكرًا</span>
                                </span>
                                <span class="button__content" content-type="error">
                                    <!-- <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <title>check</title>
                                        <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                    </svg> -->
                                    <span class="button__message">حاول مرة اخرى</span>
                                </span>
                            </button>
                        </div>
                        <div v-if="checkFormData()" class="we-have-your-details">
                            <span>
                                لقد تركت تفاصيلك, سوف نتواصل معك في اقرب فرصة ممكنة, تابع صفحتنا على
                                <a href="https://instagram.com/aws_wattad21g" aria-label="aws_wattad21g"
                                    tooltip="aws_wattad21g">الانستغرام
                                </a> لمزيد من النتائج
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { required } from '@vuelidate/validators'
// import hash from 'object-hash'

export default {
    name: 'ContactForm',
    data: () => ({
        animatedButton: '',
        isFormSubmited: false,
        nameIsValid: true,
        numberIsValid: true,
        form: {
            fullName: '',
            phoneNumber: ''
        }
    }),
    validations() {
        return {
            fullName: { required },
            phoneNumber: { required }
        }
    },
    mounted() {
        const submitBtn = document.getElementById('submitBtn')
        // initialize the button element as an instance of the AnimatedButton class
        this.animatedButton = new AnimatedButton(submitBtn)
    },
    methods: {
        hashObject(object) {
            return btoa(JSON.stringify(object))
            // return hash(JSON.stringify(object), { algorithm: 'md5', encoding: 'base64' })
        },
        setCookie(data) {
            this.$cookies.set('form', data)
        },
        getCookie() {
            return this.$cookies.get('form')
        },
        isCookieExists() {
            return this.$cookies.isKey('form')
        },
        checkFormData() {
            if (this.isCookieExists()) {
                const formData = JSON.parse(atob(this.getCookie()))
                if (formData.phoneNumber === this.form.phoneNumber) {
                    return true
                }
                return false
            }
            return false
        },
        isNumber(event) {
            // this.form.phoneNumber = this.form.phoneNumber.replace(/[^0-9]/g, '')
            // if (!/[a-zA-Z0-9\s]/.test(event.key)) {
            if (!/[0-9\s]/.test(event.key)) {
                this.ignoredValue = event.key ? event.key : ''
                event.preventDefault()
            }
        },
        encode(data) {
            return Object.keys(data)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
                .join('&')
        },
        handleSubmit() {
            // const submitBtn = document.getElementById('submitBtn')
            // // initialize the button element as an instance of the AnimatedButton class
            // new AnimatedButton(submitBtn)

            // isCookieExists
            if (this.isCookieExists()) {
                const isDataSaved = this.checkFormData()
                if (isDataSaved) {
                    this.isFormSubmited = true
                } else {
                    this.newFormSubmission()
                }
            } else {
                this.newFormSubmission()
            }
        },
        async newFormSubmission() {
            // Hash Obejct
            // const hashedObject = this.hashObject({ fullName: this.form.fullName, phoneNumber: this.form.phoneNumber })
            // console.log(hashedObject)
            // this.setCookie(hashedObject)
            // this.animatedButton._onButtonClick()

            this.nameIsValid = this.validateField(this.form.fullName)
            this.numberIsValid = this.validateField(this.form.phoneNumber)

            if (this.nameIsValid && this.numberIsValid) {
                // if ANY fail validation
                await fetch('/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: this.encode({
                        'form-name': 'contact',
                        ...this.form
                    })
                })
                    .then((response) => {
                        this.animatedButton._onButtonClick(response)
                        // Hash Obejct
                        const hashedObject = this.hashObject(
                            { fullName: this.form.fullName, phoneNumber: this.form.phoneNumber })

                        // Set Cookie
                        if (response.status === 200) {
                            this.setCookie(hashedObject)
                        }
                    })
                    .catch(() => alert('حدث خطأ ما. تأكد من البيانات التي ادخلتها وحاول مرة اخرى!'))
            } else {
                alert('حدث خطأ ما. تأكد من البيانات التي ادخلتها وحاول مرة اخرى')
            }
        },
        validateField(value) {
            return value.trim() !== ''
        }
    }
}

export class AnimatedButton {
    // button local variables
    BUTTON_STATE = {
        DEFAULT: 'default',
        LOADING: 'loading',
        SUCCESS: 'success',
        ERROR: 'error'
    }

    LOADING_TIME = 3000
    // PAUSE_BEFORE_CONFETTI = 250

    constructor(buttonEl) {
        this.buttonEl = buttonEl
        this.contentEls = this.buttonEl.querySelectorAll('.button__content')
        this._buttonState = this.BUTTON_STATE.DEFAULT

        this.buttonEl.addEventListener('click', this._onButtonClick)
        // window.addEventListener(RESET_EVENT, this._reset)
        // this.response = httpResponse
    }

    // On button click set correct states, update UI as needed, fake a timeout, show confetti
    _onButtonClick = async (response = undefined) => {
        this._buttonState = this.BUTTON_STATE.LOADING
        this.buttonEl.setAttribute('aria-disabled', true)
        this.buttonEl.tabIndex = -1
        this._updateUI()
        await this._animationTimeout(this.LOADING_TIME)
        if (response.status === 200) {
            this._buttonState = this.BUTTON_STATE.SUCCESS
            this._updateUI()
            if (this._buttonState === this.BUTTON_STATE.SUCCESS) {
                setTimeout(() => {
                    window.open('https://instagram.com/aws_wattad21g', '_blank')
                }, 1000)
            }
        } else if (response.status === 400 ||
            response.status === 404 ||
            response.status === 500 ||
            response.status === 501 ||
            response.status === 502) {
            this._buttonState = this.BUTTON_STATE.ERROR
            this._updateUI()
            setTimeout(() => {
                this._reset()
            }, 1500)
        }
        // }
        // await this._animationTimeout(this.PAUSE_BEFORE_CONFETTI)
        // confetti({
        //     particleCount: 100,
        //     startVelocity: 20,
        //     spread: 140
        // })
    }

    // update the button state attribute depending on the button's current state
    _updateUI = () => {
        this.buttonEl.setAttribute('button-state', this._buttonState)
    }

    // Fake a timeout
    _animationTimeout = (timeoutDuration) => {
        return new Promise(resolve => setTimeout(resolve, timeoutDuration))
    }

    // Reset the button to the default state
    _reset = () => {
        this._buttonState = this.BUTTON_STATE.DEFAULT
        this.buttonEl.removeAttribute('aria-disabled')
        this.buttonEl.removeAttribute('tabindex')
        this._updateUI()
    }
}

</script>

<style lang="scss">
#contact-form-wrapper {
    width: 100%;
    margin: 50px 0;

    .container {
        width: 100%;

        .row {
            width: 90%;
            max-width: 1600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;

            .title {
                h3 {
                    font-size: clamp(24px, 2.083vw, 40px);
                    margin: 0 0 40px 0;
                    text-align: center;
                }
            }

            .form-wrapper {
                width: 100%;

                .form {
                    width: 95%;
                    max-width: 500px;
                    margin: auto;
                    position: relative;
                    transition: all .4s;

                    .inputs {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr 1fr;
                        gap: 40px;
                        margin: 0 0 30px 0;

                        .input-group {
                            position: relative;

                            label {
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translate(0, -50%);
                                transition: all .3s;
                                font-size: 16px;
                            }

                            input {
                                width: 100%;
                                height: 50px;
                                color: #f1f1f1;
                                border-radius: 14px;
                                border: none;
                                background: #242b3b;
                                box-shadow: 5px 5px 10px #0e1118,
                                    -5px -5px 10px #3a455e;
                                padding: 0 10px 0 0;
                                font-size: 16px;

                                &:focus,
                                &:not(:placeholder-shown) {

                                    +label {
                                        transform: translate(0, -185%);
                                        background: #242b3b;
                                        box-shadow: -1px -4px 3px #3a455e;
                                        padding: 0 10px;
                                        border-radius: 5px 5px 0 0;
                                    }
                                }

                                &.is-invalid {
                                    box-shadow: 1px 1px 5px #cc2200,
                                        -1px -1px 5px #cc2200;
                                }
                            }
                        }
                    }

                    .submit-btn {
                        --transition-duration: .35s;

                        button {
                            background-color: var(--yellow);
                            width: 100%;
                            height: 50px;
                            font-size: 16px;
                            font-weight: 900;
                            color: var(--darkBlue);
                            border-radius: 14px;
                            box-shadow: 5px 5px 10px #0e1118,
                                -5px -5px 10px #3a455e;
                            cursor: pointer;

                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            position: relative;

                            &:not([button-state="default"]) {
                                pointer-events: none;
                            }

                            &:is(:focus-visible) {
                                outline-offset: 2px;
                            }

                            .button__content {
                                display: flex;
                                align-items: center;
                                gap: .5rem;
                                opacity: 1;
                                visibility: visible;
                                transition:
                                    transform var(--transition-duration) ease-in,
                                    opacity var(--transition-duration) ease-in .25s;
                                pointer-events: none;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);

                                &[content-type="loading"],
                                &[content-type="success"],
                                &[content-type="error"] {
                                    transform: translate(-50%, calc(-50% + 2rem));
                                    opacity: 0;
                                }

                                // .button__message {
                                //     color: white;
                                // }

                                .button__icon {
                                    height: 1.5rem;
                                    width: 1.5rem;
                                }

                                &[content-type="default"] {
                                    .button__icon {
                                        fill: var(--darkBlue);
                                    }
                                }

                                // &[content-type="success"] {
                                //     .button__icon {
                                //         fill: hsl(130 100% 50% / 100%);
                                //     }
                                // }

                                .button__dot {
                                    height: .4rem;
                                    width: .4rem;
                                    border-radius: 50%;
                                    background-color: white;

                                    &.button__dot--first {
                                        animation: dotAnimation 1.25s ease-in-out infinite 0s;
                                    }

                                    &.button__dot--second {
                                        animation: dotAnimation 1.25s ease-in-out infinite .3s;
                                    }

                                    &.button__dot--thirs {
                                        animation: dotAnimation 1.25s ease-in-out infinite .6s;
                                    }
                                }

                            }

                            &[button-state="loading"] {
                                .button__content[content-type="default"] {
                                    transform: translate(-50%, calc(-50% - 3rem));
                                    opacity: 0;
                                }
                            }

                            &[button-state="loading"] {
                                .button__content[content-type="loading"] {
                                    transform: translate(-50%, -50%);
                                    opacity: 1;
                                }
                            }

                            &[button-state="success"],
                            &[button-state="error"] {

                                .button__content[content-type="loading"],
                                .button__content[content-type="default"] {
                                    transform: translate(-50%, calc(-50% - 3rem));
                                    opacity: 0;
                                }
                            }

                            &[button-state="success"] {
                                .button__content[content-type="success"] {
                                    transform: translate(-50%, -50%);
                                    opacity: 1;
                                }
                            }

                            &[button-state="error"] {
                                .button__content[content-type="error"] {
                                    transform: translate(-50%, -50%);
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .we-have-your-details {
                        position: relative;
                        margin: 10px 0 0 0;
                        transition: all .4s;
                        animation: fromTop .5s;

                        span {
                            font-size: 14px;

                            a {
                                display: inline-block;
                                text-decoration: underline;

                                &:hover {
                                    color: var(--yellow);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes fromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes dotAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}
</style>
