<template>
    <div class="swiper-wrapper">
        <swiper :effect="'cube'" :grabCursor="true" :cubeEffect="{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
        }" :pagination="true" :modules="modules" class="mySwiper">
            <swiper-slide>
                <img src="@/assets/images/customers/7.webp" />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/images/customers/1.webp" />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/images/customers/2.webp" />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/images/customers/3.webp" />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/images/customers/4.webp" />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/images/customers/5.webp" />
            </swiper-slide>
            <swiper-slide>
                <img src="@/assets/images/customers/6.webp" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script lang="js">
import { Swiper, SwiperSlide } from 'swiper/vue'
// Import Swiper styles
import 'swiper/css'

import 'swiper/css/effect-cube'
import 'swiper/css/pagination'
// import required modules
import { EffectCube, Pagination } from 'swiper/modules'

export default {
    name: 'ResultsComponent',
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        return {
            modules: [EffectCube, Pagination]
        }
    }
}
</script>

<style lang="scss">
.swiper-wrapper {
    margin: 50px 0 0 0;
}

.swiper {
    width: 90%;
    max-width: 600px;
    height: auto;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-pagination-bullet {
    background: var(--yellow);
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -50px;
}
</style>
