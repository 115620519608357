<template>
  <router-view />
</template>

<script lang="js">
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import url(./assets/style/reset.scss);

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
